// src/authConfig.js

export const msalConfig = {
    auth: {
      clientId: 'fdccf0cf-5b9b-41ed-9ccf-eba8abfccfe5', // Replace with your Application (client) ID
      authority: 'https://whatthephishapi.b2clogin.com/threat.whatthephish.ai/b2c_1_signupsignin', // Replace with your tenant and policy details
      redirectUri: 'https://threat.whatthephish.ai/', // Replace with your redirect URI
     // postLogoutRedirectUri: 'https://threat.whatthephish.ai/', // Replace with your post logout URI
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  };
  
  export const loginRequest = {
    scopes: ['openid'],
  };
  