import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../components/authConfig';
import logo from '../components/main_logo.png'; // Adjust the path as necessary
import './Login.css';

function Login() {
  const { instance } = useMsal();
    
    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };


  return (
    <div className="login-page">
      <img src={logo} alt="Company Logo" className="login-logo" />
      <h2>Welcome to the Company Report Dashboard</h2>
      <button onClick={handleLoginRedirect}>Sign in with Microsoft</button>
    </div>
  );
}

export default Login;
