import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Header from './components/Header';
import Footer from './components/Footer';
import ReportSelection from './components/ReportSelection';
import DetailedIncidentReport from './pages/DetailedIncidentReport';
import SenderDomainAnalysis from './pages/SenderDomainAnalysis';
import ThreatDetectionAccuracy from './pages/ThreatDetectionAccuracy';
import PhishingCharacteristics from './pages/PhishingCharacteristics';
import CompanyThreatReport from './pages/CompanyThreatReport';
import Login from './pages/Login';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <AuthenticatedTemplate>
            <Routes>
              <Route path="/" element={<ReportSelection />} />
              <Route path="/detailed-incident-report" element={<DetailedIncidentReport />} />
              <Route path="/sender-domain-analysis" element={<SenderDomainAnalysis />} />
              <Route path="/threat-detection-accuracy" element={<ThreatDetectionAccuracy />} />
              <Route path="/phishing-characteristics" element={<PhishingCharacteristics />} />
              <Route path="/company-threat-report" element={<CompanyThreatReport />} />
            </Routes>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </UnauthenticatedTemplate>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
