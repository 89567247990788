import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ReportSelection.css';

function ReportSelection() {
  const [companyID, setCompanyID] = useState('');
  const navigate = useNavigate();

  const handleNavigation = (reportPage) => {
    if (!companyID) {
      alert('Please enter a valid Company ID.');
      return;
    }
    navigate(`/${reportPage}?companyID=${companyID}`);
  };

  return (
    <section id="report-selection">
      <h2>Select a Report</h2>
      <div className="company-id-input">
        <label htmlFor="companyID">Enter Company ID:</label>
        <input
          type="text"
          id="companyID"
          value={companyID}
          onChange={(e) => setCompanyID(e.target.value)}
          required
        />
      </div>
      <div className="report-buttons">
        <button onClick={() => handleNavigation('detailed-incident-report')}>Detailed Incident Report</button>
        <button onClick={() => handleNavigation('sender-domain-analysis')}>Sender Domain Analysis</button>
        <button onClick={() => handleNavigation('threat-detection-accuracy')}>Threat Detection Accuracy</button>
        <button onClick={() => handleNavigation('phishing-characteristics')}>Phishing Characteristics</button>
        <button onClick={() => handleNavigation('company-threat-report')}>Company Threat Report</button>
      </div>
    </section>
  );
}

export default ReportSelection;
