import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './main_logo.png'; // Adjust path if necessary

function Header() {
  return (
    <header className="App-header">
      <Link to="/">
        <img src={logo} alt="Company Logo" className="App-logo" />
      </Link>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </header>
  );
}

export default Header;
