import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Chart from 'chart.js/auto';
import './ReportPage.css';

function CompanyThreatReport() {
  const [data, setData] = useState([]);
  const { companyID } = useParams();

  useEffect(() => {
    if (companyID) {
      loadThreatReport(companyID);
    }
  }, [companyID]);

  const loadThreatReport = async (companyID) => {
    try {
      const response = await fetch(`/company-threat-report`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyID })
      });
      if (!response.ok) throw new Error('Failed to load threat report.');
      const data = await response.json();
      setData(data);
      displayChart(data);
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error loading the threat report.');
    }
  };

  const displayChart = (data) => {
    const ctx = document.getElementById('threat-report-chart').getContext('2d');
    // Add chart.js logic here
  };

  return (
    <div>
      <h1>Company Threat Report</h1>
      <canvas id="threat-report-chart"></canvas>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Timestamp</th>
            <th>Sender Email</th>
            <th>Sender Domain</th>
            <th>Classification</th>
            <th>Certainty</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{new Date(row.timestamp).toLocaleString()}</td>
              <td>{row.senderEmail}</td>
              <td>{row.senderDomain}</td>
              <td>{row.classification}</td>
              <td>{row.certainty.toFixed(2)}</td>
              <td>{row.details}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CompanyThreatReport;
