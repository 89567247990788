import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Chart from 'chart.js/auto';
import './ReportPage.css';

function ThreatDetectionAccuracy() {
  const [data, setData] = useState([]);
  const { companyID } = useParams();

  useEffect(() => {
    if (companyID) {
      loadDetectionAccuracy(companyID);
    }
  }, [companyID]);

  const loadDetectionAccuracy = async (companyID) => {
    try {
      const response = await fetch(`/threat-detection-accuracy`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyID })
      });
      if (!response.ok) throw new Error('Failed to load detection accuracy.');
      const data = await response.json();
      setData(data);
      displayChart(data);
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error loading the detection accuracy.');
    }
  };

  const displayChart = (data) => {
    const ctx = document.getElementById('detection-accuracy-chart').getContext('2d');
    // Add chart.js logic here
  };

  return (
    <div>
      <h1>Threat Detection Accuracy</h1>
      <canvas id="detection-accuracy-chart"></canvas>
      <table>
        <thead>
          <tr>
            <th>Classification</th>
            <th>Average Certainty</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.classification}>
              <td>{row.classification}</td>
              <td>{row.avg_certainty.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ThreatDetectionAccuracy;
